<template>
  <section
    :class="classes"
    v-if="!loading">
    <article
      class="catalog-intro__illustration"
      v-if="hasPictures">
      <ui-hero
        :figures="pictures"
        fit="cover"
        v-scroll-reveal
      />
    </article>

    <!-- Body -->
    <div class="container">
      <article class="catalog-intro__body">
        <div class="catalog-intro__inner">
          <div class="catalog-intro__logo">
            <img 
              alt="logo" 
              :src="logo" 
            />
          </div>

          <div class="catalog-intro__info">
            <div>
              <h1
                class="catalog-intro__title"
                v-scroll-reveal
              >{{ shop.name }}</h1>

              <p
                class="catalog-intro__description"
                v-if="shop.description"
                v-html="shop.description"
              ></p>
            </div>

            <div class="catalog-intro__links">
              <div
                class="catalog-intro__meta"
                v-if="!$basil.isNil(website) && !$basil.isEmpty(website)">
                <a
                  class="catalog-intro__intro-link -url"
                  :href="website"
                  icon-post="open"
                  :size="$pepper.Size.S"
                  target="_blank">
                  <svg-network class="svg-icon icon" />
                  <div class="url-label">
                    <span>{{ website }}</span>
                  </div>

                  <ui-icon 
                    class="open-icon" 
                    glyph="open" 
                  />
                </a>
              </div>

              <div class="catalog-intro__meta socials">
                <actions-button
                  :appearance="$pepper.Appearance.DEFAULT"
                  class="catalog-intro__social-link"
                  :href="s.href"
                  :key="i"
                  target="_blank"
                  :size="$pepper.Size.M"
                  v-for="(s, i) in socials">
                  <component
                    :is="s.component"
                    class="svg-icon"
                  />
                </actions-button>
              </div>

              <div
                class="catalog-intro__meta"
                v-if="(!$basil.isNil(email) && !$basil.isEmpty(email))">
                <actions-button
                  class="catalog-intro__intro-link"
                  icon-pre="envelope"
                  :size="$pepper.Size.S"
                  @click="openEmail"
                >{{ email }}</actions-button>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article class="catalog-intro__body -desktop">
        <div class="catalog-intro__inner">
          <div class="catalog-intro__logo">
            <img 
              alt="logo" 
              :src="logo" 
            />
          </div>
        </div>

        <div class="catalog-intro__info">
          <div class="row catalog-intro__info-title">
            <div>
              <h1
                class="catalog-intro__title"
                v-scroll-reveal
              >{{ shop.name }}</h1>

              <p
                class="catalog-intro__description"
                v-if="shop.description"
                v-html="shop.description"
              ></p>
            </div>

            <div 
              class="catalog-intro__links" 
              :key="`icon_${key}`">
              <div
                class="catalog-intro__meta"
                v-if="!$basil.isNil(website) && !$basil.isEmpty(website)">
                <a
                  class="catalog-intro__intro-link -url"
                  :href="website"
                  icon-post="open"
                  :size="$pepper.Size.S"
                  target="_blank">
                  <svg-network class="svg-icon icon" />

                  <div class="url-label">
                    <span>{{ website }}</span>
                  </div>

                  <ui-icon 
                    class="open-icon" 
                    glyph="open" 
                  />
                </a>
              </div>

              <div class="catalog-intro__meta socials">
                <actions-button
                  :appearance="$pepper.Appearance.DEFAULT"
                  class="catalog-intro__social-link"
                  :key="i"
                  @click="onSocialClick(s)"
                  v-for="(s, i) in socials">
                  <component
                    class="svg-icon"
                    :class="{ '--full': s.style != null }"
                    :is="s.component"
                  />
                </actions-button>
              </div>

              <div
                class="catalog-intro__meta"
                v-if="(!$basil.isNil(email) && !$basil.isEmpty(email))">
                <actions-button
                  class="catalog-intro__intro-link"
                  icon-pre="envelope"
                  :size="$pepper.Size.S"
                  @click="openEmail"
                >{{ email }}</actions-button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </section>

  <section
    class="catalog-intro -is-loading"
    v-else>
    <ui-loader />
  </section>
</template>

<script>
import Config from '@/config'
import UiHero from '@/modules/catalog/components/ui/hero'

export default {
  name: 'CatalogShopIntro',

  components: {
    UiHero
  },

  inject: [
    '$embed', 
    '$localStorage'
  ],

  props: {
    size: {
      type: String,
      default: 'm'
    }
  },

  data() {
    return {
      key: 1,
    }
  },

  computed: {
    classes() {
      return {
        'catalog-intro': true,
        '-s': this.size === 's'
      }
    },

    email() {
      return this.$basil.get(this.shop, 'contactEmail', null)
    },

    hasPictures() {
      return this.pictures && this.pictures.length > 0
    },

    loading() {
      return this.$embed.loading
    },

    logo() {
      return this.$basil.get(this.shop, 'logo', `${ Config.cdn }statics/images/logos/connect/color.svg`)
    },

    pictures() {
      return this.shop.banners || []
    },

    phoneLink() {
      return `tel://${this.shop.phone}`
    },

    mailto() {
      return `mailto://${this.email}`
    },

    service() {
      return this.$basil.get(this.shop, 'service')
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    socials() {
      let ret = []

      if(!this.loading && this.shop.socials) {
        ret = this.shop.socials

        ret.forEach( s => {
          s.icon = s.type
          s.style = s.type === 'instagram' ? 'full' : null
          s.component = `svg-${s.type}`
          s.label = this.$t(`sayl-front.social_${s.type}_cta`)
        })
      }

      return ret
    },

    website() {
      return this.$basil.get(this.shop, 'website', null)
    }
  },

  methods: {
    onSocialClick(social) {
      window.open(social.href, '_blank')
    },

    openEmail() {
      window.open(this.mailto, '_blank')
    }
  },
}
</script>

<template>
  <div
    class="t-spa"
    v-if="hasCategories">
    <div
      class="t-spa__category category"
      :class="`category-${c.slug}`"
      :data-id="c.id"
      :data-slug="c.slug"
      :key="i"
      :id="`cat_${c.slug}`"
      v-for="(c, i) in filteredCategories">
      <header
        class="category__header"
        :id="c.id"
        v-scroll-reveal>
        <div class="row">
          <h3 class="category__title">{{ c.name }}</h3>
        </div>

        <p
          class="category__description"
          v-if="c.description"
        >{{ c.description }}</p>
      </header>

      <div class="category__body">
        <ul class="category__products">
          <li
            v-for="product in c.products"
            :key="product.id"
            class="category__product"
            v-scroll-reveal>
            <product-card
              :disabled="product.hidden"
              :product="getProduct(product.id)"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    v-else
    class="t-spa__categories"
  ><data-empty :title="$t('conn3ct-store.catalog_no_products')" /></div>
</template>

<script>
import ProductCard from '@/modules/catalog/components/product/card'

export default {
  name: 'CatalogTemplateSpa',

  components: {
    ProductCard,
  },

  inject: [
    '$catalog',
    '$embed',
    '$nft'
  ],

  data() {
    return{
      debounce: null,
      clicked: false
    }
  },

  computed: {
    filteredCategories() {
      const cats = this.categories.filter(c => c.selected)
      
      if(cats.length === 0) {
        return this.categories
      }
      return cats
    },

    isLoading() {
      return this.$catalog.loading
    },

    categories() {
      let cats = this.$basil.get(this.$catalog, 'catalog.categories', [])
      return cats ? cats.filter((c) => this.$basil.get(c, 'products', []).length > 0) : []
    },

    hasCategories() {
      return this.categories && this.categories.length > 0
    },
  },

  methods: {
    getProduct(productId) {
      let product = this.$nft.findById(productId)
      let prod = this.$catalog.product.getProductById(productId)

      product.mergeAdditionnalData(prod)

      return product
    }
  }
}
</script>

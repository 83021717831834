<template>
  <div :class="classes">
    <div class="ui-hero__inner">
      <picture
        class="ui-hero__picture"
        v-for="(image,i) in images"
        :key="i">
        <ui-image
          class="ui-hero__image"
          :fit="fit"
          :height="height"
          :src="image"
          :width="width"
          />
      </picture>
    </div>

    <nav
      v-if="!isOne && false"
      class="ui-hero__nav">
      <actions-button
        appearance="subtle"
        class="ui-hero__prev"
        icon-pre="arrow-left"
        @click="prev"></actions-button>

      <div class="ui-hero__position">{{ currentIndex+1 }} / {{ nFigures }}</div>

      <actions-button
        appearance="subtle"
        class="ui-hero__next"
        icon-pre="arrow-right"
        @click="next"></actions-button>
    </nav>
  </div>
</template>

<script>
import UiImage from '@/components/ui/image'

export default {
  name: 'Hero',

  components: {
    UiImage
  },

  data(){
    return {
      currentIndex: 0,
    }
  },

  props: {
    figures: {
      type: Array
    },

    fit:{
      type: String,
      default: 'cover'
    },

    size: {
      type: String,
      default: 'm'
    }
  },

  computed: {
    classes(){
      return {
        'ui-hero': true,
        '-s': this.size === 's',

        '-is-one': this.images.length === 1,
        '-is-two': this.images.length === 2,
        '-is-three': this.images.length === 3,
        '-is-five': this.images.length === 5,

        '-fit-cover': this.fit === 'cover',
        '-fit-contain': this.fit === 'contain',
      }
    },

    images(){
      let indexes = [
        this.currentIndex
      ]

      if (!this.isOne){
        indexes.push(this.currentIndex + 1 < this.nFigures ? this.currentIndex + 1 : 0)
      }

      return indexes.map(i => this.figures[i])
    },

    isOne(){
      return this.nFigures === 1
    },

    height(){
      return 459 * 2
    },

    nFigures(){
      return this.figures.length
    },

    width(){
      return 816 * 2
    }
  },

  methods: {
    goTo(n){
      this.currentIndex = Math.max(0, Math.min(this.nFigures, n))
    },

    next(){
      this.goTo(this.currentIndex + 1 < this.nFigures ? this.currentIndex + 1 : 0)
    },

    prev(){
      this.goTo(this.currentIndex - 1 >= 0 ?  this.currentIndex - 1 : this.nFigures - 1)
    },
  }
}
</script>

<template>
  <nav
    class="nav-categories"
    v-if="nCategories > 0">
    <ul class="nav-categories__categories">
      <li
        class="nav-categories__category"
        :key="$basil.uniqId(i)"
        v-for="(c, i) in categories">
        <actions-button
          :appearance="$pepper.Appearance.DEFAULT"
          class="nav-categories__button"
          :class="{ '-is-selected': c.selected }"
          :size="$pepper.Size.M"
          @click="onCategoryClick(c)"
        >{{ c.name }}</actions-button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavCategories',

  inject: ['$catalog'],

  computed: {
    categories() {
      let ret = this.$basil.get(this.$catalog, 'catalog.categories', []) || []
      return ret.filter(c => this.$basil.get(c, 'products.length', 0) > 0)
    },

    nCategories() {
      return this.categories.length
    }
  },

  methods: {
    onCategoryClick(cat) {
      cat.selected = !cat.selected
    }
  }
}
</script>

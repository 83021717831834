<template>
  <main>
    <catalog-shop-intro size="s" />

    <div
      class="view-catalog container"
      v-if="!loading">
      <!-- Content -->
      <div class="view-catalog__content">
        <!-- Body -->
        <div class="view-catalog-body">
          <div
            class="view-catalog-body__loader flow -is-loading"
            v-if="isCatalogLoading">
            <ui-loader />
          </div>

          <!-- Header -->
          <header class="view-catalog-header">
            <div class="view-catalog-header__categories">
              <nav-categories v-if="!isCatalogLoading && hasCategories" />
            </div>
          </header>

          <!-- Template -->
          <catalog-template-spa
            :class="{ 'view-catalog-body__is-loading': isCatalogLoading }"
            v-if="!isCatalogLoading"
          />
        </div>
      </div>

      <!-- Children -->
      <router-view :key="$route.path" />
    </div>
  </main>
</template>

<script>
import CatalogShopIntro from '@/modules/catalog/components/catalog/intro'
import CatalogTemplateSpa from '@/modules/catalog/components/templates/spa'
import NavCategories from '@/modules/catalog/components/ui/nav-categories'

export default {
  name: 'CatalogView',

  components: {
    CatalogShopIntro,
    CatalogTemplateSpa,
    NavCategories,
  },

  inject: [
    '$catalog',
    '$embed',
  ],

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    hasCategories() {
      return this.$basil.get(this.$catalog, 'catalog.categories.length', 0) > 1
    },

    isCatalogLoading() {
      return this.$catalog.loading
    },

    service() {
      return this.$basil.get(this.$embed, 'shop.model.service')
    },

    title() {
      return this.$t('conn3ct-store.catalog_menu_title')
    }
  },

  async mounted() {
    try {
      this.loading = true
      await this.$catalog.init({ id: this.$basil.get(this.service, 'menuId'), service: 'virtual' })
    } catch(e) {
      $console.error(e)
    } finally {
      this.loading = false
    }
  },
}
</script>
